import React from 'react';
import { Box, Typography, Link } from '@mui/material';

const Footer: React.FC = () => {
    return (
        <Box
            sx={{
                backgroundColor: '#f5f5f5',
                p: 2,
                mt: 'auto',
                textAlign: 'center',
                borderTop: '1px solid #e0e0e0',
            }}
        >
            <Typography variant="body2" color="textSecondary">
                ©  <Link color="inherit" href="https://tecnolokid.com.br/">
                    Tecnolokid
                </Link>{' '}
                {new Date().getFullYear()}
                {'.'} Todos os direitos reservados.
            </Typography>
            <Typography variant="body2" color="textSecondary">
                <Link href="/privacy" color="inherit" sx={{ mx: 1 }}>
                    Política de Privacidade
                </Link>
                |
                <Link href="/terms" color="inherit" sx={{ mx: 1 }}>
                    Termos de Uso
                </Link>
                |
                <Link href="/contact" color="inherit" sx={{ mx: 1 }}>
                    Contato
                </Link>
            </Typography>
        </Box>
    );
};

export default Footer;
