// src/firebaseConfig.ts
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyDC4jCbqkzKi1Qi8wnSSSUIy_ifv-Dq8So",
    authDomain: "pdc-usuarios.firebaseapp.com",
    projectId: "pdc-usuarios",
    storageBucket: "pdc-usuarios.appspot.com",
    messagingSenderId: "547633112295",
    appId: "1:547633112295:web:75975856ce5e2edb9bee42",
  };

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
