import React from 'react';
import { Grid, Typography, Box, Button } from '@mui/material';
import { useLocation } from 'react-router-dom';

const Player: React.FC = () => {
    const location = useLocation();
    const videoUrl = new URLSearchParams(location.search).get('videoUrl');
    const videoTitle = new URLSearchParams(location.search).get('title');
    const videoDescription = new URLSearchParams(location.search).get('description');
    const videoDescription2 = new URLSearchParams(location.search).get('description2');
    const bncc = new URLSearchParams(location.search).get('bncc');

    return (
        <Box sx={{ flexGrow: 1, p: 2, ml: 10 }} >
            <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                    {/* TODO: Trocar player para bloquear download do vídeo */}
                    <iframe
                        width="100%"
                        height="498px"
                        src={videoUrl || ''}
                        title="Video Player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
                        allowFullScreen
                        style={{ borderRadius: "10px" }}
                    />
                    <Typography>Proibida a distribuição sem autorização</Typography>
                    <Typography>Direitos autorais reservados</Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <Box sx={{
                            background: "#d6d7cf",
                            borderRadius: "10px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            textAlign: "center",
                            p: 2,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            wordWrap: 'break-word'
                        }}>
                            <Typography variant="h5" gutterBottom>
                                {videoTitle || 'Título do Vídeo'}
                            </Typography>
                            <Typography variant="body1" gutterBottom>Habilidades BNCC: {bncc || 'Habilidade BNCC'}</Typography>
                        </Box>
                        <Box sx={{
                            background: "#d6d7cf",
                            borderRadius: "10px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            textAlign: "center",
                            p: 2,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            wordWrap: 'break-word'
                        }}>
                            <Typography variant="h6" gutterBottom>
                                Atividade Prática 1
                            </Typography>
                            <Typography variant="body2">
                                {videoDescription || 'Descrição do Vídeo'}
                            </Typography>
                        </Box>
                        <Box sx={{
                            background: "#d6d7cf",
                            borderRadius: "10px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            textAlign: "center",
                            p: 2,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            wordWrap: 'break-word'
                        }}>
                            <Typography variant="h6" gutterBottom>
                                Atividade Prática 2
                            </Typography>
                            <Typography variant="body2">
                                {videoDescription2 || 'Descrição do Vídeo'}
                            </Typography>
                        </Box>
                        <Box sx={{
                            background: "#2096f3",
                            borderRadius: "10px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            textAlign: "center",
                            p: 2,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            wordWrap: 'break-word'
                        }}>
                            <Typography variant="h6" gutterBottom>
                                Recursos para a Aula
                            </Typography>
                            <Button sx={{ color: "#fafafa" }}>Baixar</Button>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Player;
