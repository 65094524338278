// src/components/View/DataTable/DataTable.tsx
import React, { useEffect, useState } from "react";
import axios from "axios";
import { MaterialReactTable, MRT_ColumnDef } from "material-react-table";
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';
import "./DataTable.css";
import schoolData from './makeData';
import { signOut } from "firebase/auth";
import { auth } from "../../../auth/firebaseConfig";
import { useNavigate } from 'react-router-dom';
import { Button } from "@mui/material";

interface RowData {
  id: number;
  Disciplinas: string;
  BNCC: string;
  Ano: string;
  Unidade: string;
  Aula: string;
  DescriçãoAtividade1: string;
  DescriçãoAtividade2: string;
  Link: string;
  Titulo: string;
}

const DataTable: React.FC = () => {
  const navigate = useNavigate();
  const [data, setData] = useState<RowData[]>([]);
  const [loading, setLoading] = useState(true);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/signin');
    } catch (error) {
      console.error('Logout Failed:', error);
    }
  };

  const handleRowClick = (row: RowData) => {
    navigate(`/player?videoUrl=${encodeURIComponent(row.Link)}&title=${encodeURIComponent(row.Titulo)}&description=${encodeURIComponent(row.DescriçãoAtividade1)}&description2=${encodeURIComponent(row.DescriçãoAtividade2)}&bncc=${encodeURIComponent(row.BNCC)}`);
  };

  const columns = React.useMemo<MRT_ColumnDef<RowData>[]>(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        size: 20, // Small width for ID column
      },
      {
        accessorKey: "Disciplinas",
        header: "Disciplinas",
        size: 90,
        minWidth: 90,  // Garantindo que a largura mínima seja respeitada
        maxWidth: 90,
        filterFn: "contains",
        filterVariant: 'select',
        filterSelectOptions: schoolData.materias.map(materia => ({ value: materia, label: materia })),
      },
      {
        accessorKey: "BNCC",
        header: "BNCC",
        size: 90,
      },
      {
        accessorKey: "ODS",
        header: "ODS",
        size: 90,
      },
      {
        accessorKey: "BNCCComputacao",
        header: "BNCC Computação",
        size: 90,
      },
      {
        accessorKey: "Ano",
        header: "Ano",
        size: 20,
        filterVariant: 'select',
        filterSelectOptions: schoolData.anoEscolar.map(ano => ({ value: ano, label: ano })),
      },
      {
        accessorKey: "Unidade",
        header: "Unidade",
        size: 20, // Small width for Unidade column
        filterFn: "contains",
        filterVariant: 'select',
        filterSelectOptions: schoolData.unidadePlataforma.map(unidades => ({ value: unidades, label: unidades })),
      },
      {
        accessorKey: "Aula",
        header: "Aula",
        size: 20, // Small width for Aula column
        filterFn: "contains",
        filterVariant: 'select',
        filterSelectOptions: schoolData.aulaPlataforma.map(aula => ({ value: aula, label: aula })),
      },
      {
        accessorKey: "Titulo",
        header: "Título",
        minWidth: 100,  // Garantindo que a largura mínima seja respeitada
        maxWidth: 100,
      },
      {
        accessorKey: "DescriçãoAtividade1",
        header: "Descrição Atividade 1",
        minWidth: 100,  // Garantindo que a largura mínima seja respeitada
        maxWidth: 100,
      },
      {
        accessorKey: "DescriçãoAtividade2",
        header: "Descrição Atividade 2",
        minWidth: 100,  // Garantindo que a largura mínima seja respeitada
        maxWidth: 100,
      },
      {
        accessorKey: "Link",
        header: "Link da Aula",
        minWidth: 100,  // Garantindo que a largura mínima seja respeitada
        maxWidth: 100,
        Cell: ({ row }) => (
          <Button onClick={() => handleRowClick(row.original)}>
            Abrir
          </Button>
        ),
      },
    ],
    []
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://db.olomkt.com.br/api/database/rows/table/532/?user_field_names=true",
          {
            headers: {
              Authorization: "Token Mb8H6a8wpNkexCwfjTO5XPl4ihbB1Pir",
            },
            params: {
              size: 10,
            },
          }
        );

        const formattedData = response.data.results.map(
          (item: any, index: number) => ({
            id: index + 1,
            Disciplinas: item.Disciplinas || "",
            BNCC: item.BNCC || "",
            BNCCComputacao: item.BNCCComputação || "",
            Ano: item.Ano || "",
            Unidade: item.Unidade || "",
            Aula: item.Aula || "",
            Titulo: item.Título || "",
            DescriçãoAtividade1: item.Descrição || "",
            DescriçãoAtividade2: item.Descrição2 || "",
            Link: item.Link || "",
            ODS: item.ODS || "",
          })
        );

        setData(formattedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <p>Carregando...</p>;
  }

  return (
    <MaterialReactTable
      columns={columns}
      data={data}
      enableStickyHeader
      localization={MRT_Localization_PT_BR}
      enableDensityToggle={false}
      enablePagination={true}
      muiTableContainerProps={{
        sx: { height: '70vh', overflowY: 'auto' },
      }}
      muiTableBodyCellProps={{
        sx: { whiteSpace: 'normal', wordWrap: 'break-word' }, // Permite quebra de linha nas células
      }}
    />
  );
};

export default DataTable;
