// src/components/View/Contact/Contact.tsx
import React from 'react';

const Contact: React.FC = () => {
    return (
        <div>
            <h1>Contato</h1>
            <p>Informações de contato vão aqui.</p>
        </div>
    );
};

export default Contact;
