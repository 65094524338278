// src/components/Dashboard.tsx
import React from 'react';
import { Button } from '@mui/material';
import { signOut } from 'firebase/auth';
import { auth } from '../../../auth/firebaseConfig';
import { useNavigate } from 'react-router-dom';

const Dashboard: React.FC = () => {
    const navigate = useNavigate();
    return (
        <div>
            <h1>Bem-vindo ao Dashboard</h1>
        </div>
    );
};

export default Dashboard;
