const anoEscolar = [
    "1","2","3","4","5","6","7","8","9","EM1","EM2","EM3","EM"
];

const unidadePlataforma = [
    "1","2","3","4","5","6","7","8"
];

const aulaPlataforma = [
    "1","2","3","4","5","6","7","8","9","10"
];

const materias = [
    "Matemática",
    "Artes",
    "Ciências",
    "Geografia",
    "Língua Portuguesa",
    "Língua Inglesa",
    "História",
    "Ling. e suas Tec.",
    "Ciências da Nat. e suas Tec.",
    "Ciências Hum. e Sociais Aplic.",
    "Matem. e suas Tec.",
    "Não se aplica",

];

export default {
    anoEscolar,
    materias,
    unidadePlataforma,
    aulaPlataforma
};