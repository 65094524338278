// src/components/Global/Header/header.tsx
import React from 'react';
import { AppBar, Toolbar, Typography, Button, Box } from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from '../../../auth/firebaseConfig';
import './header.css'; // Importando o CSS
import logo from '../../../assets/logo_branca.png'; // Importando a imagem SVG

const Header: React.FC = () => {
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/signin');
    } catch (error) {
      console.error('Logout Failed:', error);
    }
  };

  return (
    <AppBar position="fixed" sx={{ backgroundColor: '#2196f3', boxShadow: 'none' }}>
      <Toolbar>
        <Box component="img" src={logo} alt="Logo" sx={{ maxWidth: 100, marginRight: 2 }} />
        <Typography variant="h6" sx={{ flexGrow: 1, display: 'flex' }}>
          <Button
            component={RouterLink}
            to="/"
            sx={{ color: 'white', fontWeight: 'bold', textTransform: 'none' }}
          >
            Home
          </Button>
          <Button
            component={RouterLink}
            to="/table"
            sx={{ color: 'white', fontWeight: 'bold', textTransform: 'none' }}
          >
            Tabela
          </Button>
          <Button
            component={RouterLink}
            to="/contact"
            sx={{ color: 'white', fontWeight: 'bold', textTransform: 'none' }}
          >
            Contato
          </Button>
        </Typography>
        <Button color="inherit" sx={{ color: 'white', fontWeight: 'bold', textTransform: 'none' }} onClick={handleLogout}>
          Logout
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
